import React, { useState } from 'react';
import { Modal, Form } from 'antd';
import { isEmpty } from 'lodash';
import {
  FieldDatePicker,
  FieldSelectCemetery,
  FieldText,
} from '../../components/fields';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import moment from 'moment';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export const BuriedEditDialog = ({
  values = {},
  popDialog,
  handleSubmit,
  fetchAll,
}) => {
  const { user } = useCurrentUser();
  const [errors, setErrors] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const isEdit = !isEmpty(values);

  const handleOk = () => {
    form.validateFields().then((values) => {
      setIsLoading(true);

      handleSubmit(values).then((res) => {
        if (isEmpty(res.error)) {
          fetchAll();
          popDialog();
        } else {
          setErrors(res.payload);
        }
        setIsLoading(false);
      });
    });
  };

  const processValue = (value) => {
    const res = { ...value };

    if (value.writtenAt) {
      res.writtenAt = moment(value.writtenAt);
    }

    return res;
  };

  function disabledDate(current) {
    // Can not select days before today and today
    return current > moment().endOf('day');
  }

  return (
    <Modal
      visible
      title={isEdit ? `Ўзгартириш` : `Янги қушиш`}
      okText={isEdit ? 'Ўзгартириш' : 'Янги қушиш'}
      cancelText="Бекор қилиш"
      onOk={handleOk}
      okButtonProps={{
        loading: isLoading,
      }}
      onCancel={popDialog}
      width={700}>
      <Form
        form={form}
        initialValues={processValue(values)}
        name="basic"
        onFinish={handleSubmit}
        {...layout}
        className="gx-form-row0">
        {user.isAdmin() && (
          <FieldSelectCemetery
            {...layout}
            name="cemetery"
            label="Қабристон"
            errors={errors}
          />
        )}
        <FieldDatePicker
          {...layout}
          InputProps={{ disabledDate }}
          name="writtenAt"
          label="Вафот этган сана"
          errors={errors}
        />
        <FieldText {...layout} name="fullName" label="ФИШ" errors={errors} />
        <FieldText
          {...layout}
          name="age"
          label="Туғилган вақти ва ёши"
          errors={errors}
        />
        <FieldText {...layout} name="xilhona" label="Хилхона" errors={errors} />
        <FieldText
          {...layout}
          name="gravedigger"
          label="Дафн этувчи гўркўв"
          errors={errors}
        />
        <FieldText
          {...layout}
          name="qabr"
          label="Кабр рақами"
          errors={errors}
        />
        <FieldText
          {...layout}
          name="oldQabr"
          label="Олдинги қабр рақами"
          errors={errors}
        />
        <FieldText
          {...layout}
          name="recorded"
          label="Қайд қилган ФХДË бўлими"
          errors={errors}
        />
        <FieldText
          {...layout}
          name="fhdyo"
          label="Далолатнома рақами"
          errors={errors}
        />
        <FieldText
          {...layout}
          name="homeAddress"
          label="Уй манзили"
          errors={errors}
        />
        <FieldText
          {...layout}
          name="causeOfDeath"
          label="Улим сабаби"
          errors={errors}
        />
        <FieldText
          {...layout}
          name="pinfl"
          label="️JSHSHIR (ПИНФЛ)"
          errors={errors}
        />
      </Form>
    </Modal>
  );
};
